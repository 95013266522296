// images
import blogImg1 from "../images/blog/blog1home.png";
import blogImg2 from "../images/blog/blog_post_image_2.webp";
import blogImg3 from "../images/blog/blog_post_image_3.webp";
import blogImg4 from "../images/blog/blog_post_image_12.webp";
import blogImg5 from "../images/blog/blog_post_image_13.webp";
import blogImg6 from "../images/blog/blog_post_image_14.webp";
import blogImg7 from "../images/blog/blog_post_image_15.webp";
import blogImg8 from "../images/blog/blog_post_image_16.webp";

// images
import blogsImg1 from "../images/blog/blog1home.png";
import blogsImg2 from "../images/blog/blog_post_image_2.webp";
import blogsImg3 from "../images/blog/blog_post_image_3.webp";
import blogsImg4 from "../images/blog/blog_post_image_1.webp";

const blogs = [
  {
    id: "1",
    title: "Leveraging Analytics for Business Growth Modernizing...",
    slug: "Leveraging-Analytics-for-Business-Growth-Modernizing",
    screens: blogImg1,
    bSingle: blogsImg1,
    description:
      "Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...",
    author: "farugia",
    authorTitle: "Sineor Consultant",
    create_at: "11/12/2024",
    comment: "35",
    thumb: "Branding",
    blClass: "format-standard-image",
  },
  {
    id: "2",
    title: "How Emerging Technologies are Shaping the Future...",
    slug: "How-Emerging-Technologies-are-Shaping-the-Future",
    screens: blogImg2,
    bSingle: blogsImg2,
    description:
      "Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...",
    author: "Andrew",
    authorTitle: "Creative Director",
    create_at: "11/02/2024",
    comment: "80",
    thumb: "Mobile App",
    blClass: "format-standard-image",
  },
  {
    id: "3",
    title: "Creating Engaging Digital for Your Audience Software...",
    slug: "Creating-Engaging-Digital-for-Your-Audience-Software",
    screens: blogImg3,
    bSingle: blogsImg3,
    description:
      "Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...",
    author: "Kurtz",
    authorTitle: "Art Director",
    create_at: "21/08/2024",
    comment: "95",
    thumb: "Technology",
    blClass: "format-video",
  },
  {
    id: "4",
    title: "How Our Software Solutions Drive Insights.",
    slug: "How-Our-Software-Solutions-Drive-Insights.",
    screens: blogImg4,
    bSingle: blogsImg4,
    description:
      "Student visa consulting agencies are equipped with professionals who specialize in the intricacies of visa applications.",
    author: "Alex",
    authorTitle: "Art Director",
    create_at: "18 / 08 / 2024",
    comment: "95",
    thumb: "Consulting",
  },
  {
    id: "5",
    title: "Exploring Emerging Trends in Software Development.",
    slug: "Exploring-Emerging-Trends-in-Software-Development.",
    screens: blogImg5,
    bSingle: blogsImg4,
    description:
      "The realm of visa applications is a and ever-changing landscape...",
    author: "Aliza",
    authorTitle: "Art Director",
    create_at: "25 / 08 / 2024",
    comment: "95",
    thumb: "Consulting",
  },
  {
    id: "6",
    title: "How Software Integration Can Improve Workflow.",
    slug: "How-Software-Integration-Can-Improve-Workflow.",
    screens: blogImg6,
    bSingle: blogsImg4,
    description:
      "Embarking on a journey to study abroad is an aspiration many students..",
    author: "Tika",
    authorTitle: "Art Director",
    create_at: "17 / 08 / 2024",
    comment: "95",
    thumb: "Consulting",
  },
  {
    id: "7",
    title: "5 ways to relieve stress during the holidays",
    slug: "5-ways-to-relieve-stress-during-the-holidays",
    screens: blogImg7,
    bSingle: blogsImg4,
    description:
      "Embarking on a journey to study abroad is an aspiration many students..",
    author: "Riley Steinmetz",
    authorTitle: "Art Director",
    create_at: "April 20, 2024",
    comment: "95",
    thumb: "Live Well",
  },
  {
    id: "8",
    title: "Baby flat head pillow - why its important",
    slug: "Baby-flat-head-pillow-why-its-important",
    screens: blogImg8,
    bSingle: blogsImg4,
    description:
      "Embarking on a journey to study abroad is an aspiration many students..",
    author: "Ken William",
    authorTitle: "Art Director",
    create_at: "April 24, 2024",
    comment: "95",
    thumb: "Get Well",
  },
];
export default blogs;
