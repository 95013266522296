import React from "react";
import { Link } from "react-router-dom";

import Bg3 from "../../../images/backgrounds/footer.gif";

import icon2 from "../../../images/icons/icon_mail_2.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pimg1 from "../../../images/clients/1.png";
import pimg2 from "../../../images/clients/2.png";
import pimg3 from "../../../images/clients/3.png";
import pimg4 from "../../../images/clients/4.png";
import pimg5 from "../../../images/clients/5.png";
import pimg6 from "../../../images/clients/6.png";
import pimg7 from "../../../images/clients/7.png";
import pimg8 from "../../../images/clients/8.png";
import pimg9 from "../../../images/clients/9.png";
import pimg10 from "../../../images/clients/10.png";
import pimg11 from "../../../images/clients/11.png";
import pimg12 from "../../../images/clients/12.png";
import pimg13 from "../../../images/clients/13.png";
import pimg14 from "../../../images/clients/14.png";
import pimg15 from "../../../images/clients/15.png";
import pimg16 from "../../../images/clients/16.png";
import pimg17 from "../../../images/clients/17.png";
import footeroffice from "../../../images/actual/footer/footer-office.png";

const partners = [
  {
    pImg: pimg1,
  },
  {
    pImg: pimg2,
  },
  {
    pImg: pimg3,
  },
  {
    pImg: pimg4,
  },
  {
    pImg: pimg5,
  },
  {
    pImg: pimg6,
  },
  {
    pImg: pimg7,
  },
  {
    pImg: pimg8,
  },
  {
    pImg: pimg9,
  },
  {
    pImg: pimg10,
  },
  {
    pImg: pimg11,
  },
  {
    pImg: pimg12,
  },
  {
    pImg: pimg13,
  },
  {
    pImg: pimg14,
  },
  {
    pImg: pimg15,
  },
  {
    pImg: pimg16,
  },
  {
    pImg: pimg17,
  },
];

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const SubmitHandler = (e) => {
  e.preventDefault();
};

var settings = {
  dots: false,
  infinite: true,
  speed: 3000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: "linear",
  arrows: false,

  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Footer = (props) => {
  return (
    <>
      <div>
        <Slider {...settings}>
          {partners.map((partner, pitem) => (
            <div key={pitem}>
              <img src={partner.pImg} alt=" " style={{ padding: "50px" }} />
            </div>
          ))}
        </Slider>
      </div>
      {/* <section>
        <div
          className=" swiper"
          style={{
            marginBottom: "25px",
            marginTop: "25px",
            paddingBottom: "15px",
          }}
        >
          
          <Slider {...settings}>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{
                  border: "1px solid black",
                }}
              >
                <i className="fa-solid fa-check"></i>
                <span>NINE OD</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>NINE OD SYRUP</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>CARTISTRONG</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>CEPOJET CV</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>AMOXYLET CV</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>CARNITIME</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{
                  border: "1px solid black",
                }}
              >
                <i className="fa-solid fa-check"></i>
                <span>TELMIVAZ- M/LN/3D</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>GLYMYJET MV</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>TICAGRIL 90</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>ROZUVADIL-CV/GOLD</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>GABAPA-NT</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>PREGADON M</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>BUDIFORT</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>ACIBILL PLUS</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>AZIPLUT F</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>FLUTICOD</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>GLYCOJET F/MAX</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>CEPOZED CV</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>GABACOL</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>NINE OD GOLD</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>CARNIBETA</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>BEHISTINE</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>FLUXOMAN</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>UBKING</span>
              </p>
            </div>
            <div className="swiper-slide">
              <p
                className="service_pill_block"
                style={{ border: "1px solid black" }}
              >
                <i className="fa-solid fa-check"></i>
                <span>EZYPRAM PLUS</span>
              </p>
            </div>
          </Slider>
        </div>
      </section> */}
      <footer
        className="site_footer footer_layout_2 "
        style={{
          backgroundImage: `url(${Bg3})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        // style={{ backgroundColor: "black" }}
      >
        <div
          className="decoration_item shape_image_1"
          style={{ marginTop: "-100px" }}
        >
          {/* <img src={icon1} alt="Techco Shape" /> */}
        </div>
        <div className="container">
          <div className="footer_main_content">
            <div className="row justify-content-lg-center">
              {/*between */}
              {/* <div className="col-lg-3 col-md-6">
                <img src={footeroffice} alt="" style={{ height: "250px" }} />
              </div> */}
              <div className="col-lg-3 col-md-6">
                <div className="footer_widget">
                  <h3
                    className="footer_info_title"
                    style={{ color: "white", fontSize: "1.4rem" }}
                  >
                    Quick Links
                  </h3>
                  <ul className="icon_list unordered_list_block">
                    <li>
                      <Link onClick={ClickHandler} to="/home">
                        <span
                          className="icon_list_text"
                          style={{ color: "white", fontSize: "0.9rem" }}
                        >
                          Home
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/about">
                        <span
                          className="icon_list_text"
                          style={{ color: "white", fontSize: "0.9rem" }}
                        >
                          About Us
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/careers">
                        <span
                          className="icon_list_text"
                          style={{ color: "white", fontSize: "0.9rem" }}
                        >
                          Careers
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        <span
                          className="icon_list_text"
                          style={{ color: "white", fontSize: "0.9rem" }}
                        >
                          Contact
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/blogs">
                        <span
                          className="icon_list_text"
                          style={{ color: "white", fontSize: "0.9rem" }}
                        >
                          Blogs
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer_widget">
                  <h3
                    className="footer_info_title"
                    style={{ color: "white", fontSize: "1.4rem" }}
                  >
                    Therapeutic Categories
                  </h3>
                  <ul className="icon_list unordered_list_block">
                    <li>
                      <Link onClick={ClickHandler} to="/cardio">
                        <span
                          className="icon_list_text"
                          style={{ color: "white", fontSize: "0.9rem" }}
                        >
                          Cardiovascular Sciences
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/neuro">
                        <span
                          className="icon_list_text"
                          style={{ color: "white", fontSize: "0.9rem" }}
                        >
                          Neuro Sciences
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/respiro">
                        <span
                          className="icon_list_text"
                          style={{ color: "white", fontSize: "0.9rem" }}
                        >
                          Respiro Sciences
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/pharma">
                        <span
                          className="icon_list_text"
                          style={{ color: "white", fontSize: "0.9rem" }}
                        >
                          Pharmaceuticals
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/critical-care">
                        <span
                          className="icon_list_text"
                          style={{ color: "white", fontSize: "0.9rem" }}
                        >
                          Critical Care
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-6">
              <div className="footer_widget">
                <h3 className="footer_info_title">Industries</h3>
                <ul className="icon_list unordered_list_block">
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      <span className="icon_list_text">Insurance</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/home_software_company">
                      <span className="icon_list_text">Banking</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/service">
                      <span className="icon_list_text">Fintech</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/home_software_company">
                      <span className="icon_list_text">Logistics</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/service">
                      <span className="icon_list_text">Retail</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/portfolio">
                      <span className="icon_list_text">Healthcare</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
              {/* <div className="col-lg-4 col-md-6">
                <div className="footer_widget pe-lg-3">
                  <h2 className="footer_info_title" style={{ color: "white" }}>
                    Newsletter
                  </h2>
                  <p style={{ color: "white" }}>
                    Sign up to saanso Pharma for weekly newsletter to get the
                    latest updates.
                  </p>
                  <form
                    className="footer_newslatter_2"
                    onSubmit={SubmitHandler}
                  >
                    <label htmlFor="footer_mail_input">
                      <img src={icon2} alt="Mail SVG Icon" />
                    </label>
                    <input
                      id="footer_mail_input"
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      style={{ backgroundColor: "white" }}
                    />
                    <button type="submit">Send</button>
                  </form>

                  <ul className="social_icons_block unordered_list">
                    <li>
                      <Link onClick={ClickHandler} to="/home_software_company">
                        <i className="fa-brands fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/home_software_company">
                        <i className="fa-brands fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/home_software_company">
                        <i className="fa-brands fa-linkedin-in"></i>
                      </Link>
                    </li>
                  </ul> 
                </div>
              </div>*/}
            </div>
          </div>
        </div>
        <div className="footer_bottom" style={{ marginTop: "20px" }}>
          <div className="container d-md-flex align-items-md-center justify-content-md-between">
            <p className="copyright_text m-0" style={{ color: "white" }}>
              Copyright © 2024 Saanso.
            </p>
            <p className="copyright_text m-0" style={{ color: "white" }}>
              Powered by Infosage Digital.
            </p>
            <ul className="icon_list unordered_list">
              <li>
                <Link onClick={ClickHandler} to="#">
                  <span className="icon_list_icon">
                    <i className="fa-solid fa-circle"></i>
                  </span>
                  <span className="icon_list_text" style={{ color: "white" }}>
                    All rights reserved.
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
