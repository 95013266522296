import Carousel from "react-bootstrap/Carousel";
import { Image } from "react-bootstrap";
import img1 from "../../images/actual/events/vizag-conference/1.png";
import img2 from "../../images/actual/events/vizag-conference/2.png";
import img3 from "../../images/actual/events/vizag-conference/3.png";
import img4 from "../../images/actual/events/vizag-conference/4.png";

import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/software-company-components/Footer/Footer";
import banner from "../../images/actual/events/eventhome/banner.png";
import React, { Fragment, useState, useEffect, useRef } from "react";
import "./transition.css";
const images = [img1, img2, img3, img4];

const VizagEvent = ({ rotationSpeed = 60, step = 0.5 }) => {
  const globeRef = useRef(null);
  const [angle, setAngle] = useState(0);
  const imageCount = images.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setAngle((prev) => prev + step);
    }, rotationSpeed);
    return () => clearInterval(interval);
  }, [rotationSpeed, step]);

  const currentIndex =
    Math.round((angle % 360) / (360 / imageCount)) % imageCount;

  return (
    <Fragment>
      <Header2 />
      <div
        style={{
          backgroundImage: `url(${banner})  `,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <h1 style={{ color: "black", padding: "10px" }}>
            AP APICON September 20th – 22nd 2024 (Vishakhapatnam)
          </h1>
        </div>
      </div>
      <div className="globe-wrapper">
        <div
          className="globe"
          ref={globeRef}
          /*style={{  }}transform: `rotateY(${angle}deg) `*/
        >
          {images.map((src, i) => {
            const rotation = i * (360 / imageCount);
            return (
              <img
                key={i}
                src={src}
                className={`globe-image ${
                  i === currentIndex ? "in-focus" : ""
                }`}
                alt={`Slide ${i}`}
                style={{
                  transform: `
                    rotateY(${rotation}deg)
                    translateZ(300px)
                    translate(-50%, -50%)
                    `,
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="container">
        <p>
          Saanso participated in the AP APICON Conference, a significant event
          organized by the Andhra Pradesh Chapter of the Association of
          Physicians, which took place in Vishakhapatnam from September 20th to
          22nd, 2024. This prestigious forum provided an outstanding opportunity
          for medical professionals to exchange research and discuss the latest
          advancements in medicine. Saanso's active engagement with attendees
          underscored its commitment to delivering high-quality healthcare
          solutions.
        </p>
        <p>
          Throughout the conference, Saanso utilized strategic and aggressive
          branding efforts to effectively showcase its diverse portfolio and
          expertise in critical therapeutic areas. This approach not only
          strengthened Saanso's brand presence but also positioned it as a key
          player among key stakeholders within the medical community. The team's
          efforts to connect with attendees and present their advanced solutions
          demonstrated their proactive approach in the healthcare sector.
        </p>
        <p>
          Saanso’s participation in AP APICON reflects its dedication to
          fostering meaningful collaborations and contributing to advancements
          in healthcare. By actively engaging in such influential forums, Saanso
          continues to support the medical community's efforts to improve
          patient care and drive innovation in various therapeutic areas.
        </p>
      </div>

      <Footer />
    </Fragment>
  );
};
export default VizagEvent;
