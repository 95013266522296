import Header2 from "../../components/Header2/Header2";
import Bg from "../../images/shapes/shape_title_under_line.svg";
import ProcessTechnology from "../../components/software-company-components/ProcessTechnology/ProcessTechnology";
import Footer from "../../components/software-company-components/Footer/Footer";
import Carousel from "react-bootstrap/Carousel";

import banner from "../../images/actual/events/eventhome/banner.png";

import React, { Fragment, useState } from "react";
import Project from "../../api/project";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import VizagEvent from "./VizagEvent";
import BheemavaramEvent from "./BheemavaramEvent";
import SccLaunch from "./SccLaunch";

const Gallery = () => {
  const [activeFilter, setActiveFilter] = useState("department1");
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <Fragment>
      <Header2 />
      <div
        style={{
          backgroundImage: `url(${banner}) `,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <h1 style={{ color: "black", padding: "10px" }}>
            {" "}
            Experience Life at Saanso:
            <br />
            Integrity, Innovation, Excellence and
            <br /> Collaboration.
          </h1>
        </div>
      </div>

      <div className="container" style={{ marginTop: "100px" }}>
        <h2>Events</h2>
        <p style={{ fontSize: "1.8rem", color: "black" }}>
          Discover Insights from Saanso's Events
        </p>

        <p style={{ fontSize: "1.3rem" }}>
          At Saanso, we prioritize showcasing our participation in past events
          to illustrate our active role in the evolving pharmaceutical and
          healthcare sectors. Our event archive includes a variety of
          engagements, from in-depth webinars and interactive workshops to
          groundbreaking product launches and our annual conferences. Each
          recorded event is a testament to our commitment to fostering
          knowledge, collaboration, and innovation.
        </p>
        <p style={{ fontSize: "1.3rem" }}>
          By exploring these past events, visitors can gain insights into the
          depth of discussions held, the advancements showcased, and the
          networking opportunities provided. These events serve as platforms
          where professionals have connected, shared innovative ideas, and
          contributed significantly to the dynamic landscape of healthcare. Dive
          into our past events to witness how Saanso continues to influence and
          shape the future of healthcare through active participation and
          leadership.
        </p>
        <p
          style={{
            padding: "10px",
            backgroundColor: "#f7f7f5",
            borderRadius: "50px",
            paddingLeft: "5%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.15rem",
          }}
        >
          Annual Budget Meeting 2025 - 2026{" "}
          <a className="btn" href="budget-2025" style={{ height: "40px" }}>
            <span className="btn_label" data-text="View Event">
              View Event
            </span>
          </a>
        </p>
        <p
          style={{
            padding: "10px",
            backgroundColor: "#f7f7f5",
            borderRadius: "50px",
            paddingLeft: "5%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.15rem",
          }}
        >
          TOSACON 2025 (HYDERABAD){" "}
          <a className="btn" href="tosacon-2025" style={{ height: "40px" }}>
            <span className="btn_label" data-text="View Event">
              View Event
            </span>
          </a>
        </p>
        <p
          style={{
            padding: "10px",
            backgroundColor: "#f7f7f5",
            borderRadius: "50px",
            paddingLeft: "5%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.15rem",
          }}
        >
          A Milestone Event: Launch of Our New SCC Division{" "}
          <a className="btn" href="ssc-launch" style={{ height: "40px" }}>
            <span className="btn_label" data-text="View Event">
              View Event
            </span>
          </a>
        </p>

        <p
          style={{
            padding: "10px",
            backgroundColor: "#f7f7f5",
            borderRadius: "50px",
            paddingLeft: "5%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.15rem",
          }}
        >
          AOI APCON September 13th to 15th 2024(Bhimavaram){" "}
          <a
            className="btn"
            href="bhimavaram-conference"
            style={{ height: "40px" }}
          >
            <span className="btn_label" data-text="View Event">
              View Event
            </span>
          </a>
        </p>
        <p
          style={{
            padding: "10px",
            backgroundColor: "#f7f7f5",
            borderRadius: "50px",
            paddingLeft: "5%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.15rem",
          }}
        >
          AP APICON September 20th – 22nd 2024 (Vishakhapatnam){" "}
          <a className="btn" href="vizag-conference" style={{ height: "40px" }}>
            <span className="btn_label" data-text="View Event">
              View Event
            </span>
          </a>
        </p>
      </div>

      {/*<div className="container" style={{ marginTop: "100px" }}>
        <br />

         <h1 style={{ display: "flex", justifyContent: "center" }}>
          A Milestone Event: Launch of Our New SCC Division
        </h1>
        <br />
        <div className="row">
          <div
            className="col-lg-6"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0px",
              paddingTop: "20px",
            }}
          >
            <Carousel controls={false} indicators={false}>
              <Carousel.Item interval={2000}>
                <Image src={img1} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img4} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img5} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img6} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img7} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img8} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img9} fluid />
              </Carousel.Item>
            </Carousel>
          </div>
          <div
            className="col-lg-6"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0px",
              paddingTop: "20px",
            }}
          >
            <Carousel controls={false} indicators={false}>
              <Carousel.Item interval={2000}>
                <Image src={img2} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img3} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img10} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img11} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img12} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img13} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img14} fluid />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <Image src={img15} fluid />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

      <VizagEvent />
      <BheemavaramEvent />  */}

      <Footer />
    </Fragment>
  );
};
export default Gallery;
