import img1 from "../../images/actual/events/toscon/1.png";
import img2 from "../../images/actual/events/toscon/2.png";
import img3 from "../../images/actual/events/toscon/3.png";
import img4 from "../../images/actual/events/toscon/4.png";

import Carousel from "react-bootstrap/Carousel";
import { Image } from "react-bootstrap";

import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/software-company-components/Footer/Footer";
import banner from "../../images/actual/events/eventhome/banner.png";
import React, { Fragment, useState, useEffect, useRef } from "react";
import "./transition.css";
const images = [img1, img2, img3, img4];

const Tosacon2025 = ({ rotationSpeed = 60, step = 0.5 }) => {
  const globeRef = useRef(null);
  const [angle, setAngle] = useState(0);
  const imageCount = images.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setAngle((prev) => prev + step);
    }, rotationSpeed);
    return () => clearInterval(interval);
  }, [rotationSpeed, step]);

  const currentIndex =
    Math.round((angle % 360) / (360 / imageCount)) % imageCount;

  return (
    <Fragment>
      <Header2 />
      <div
        style={{
          backgroundImage: `url(${banner})  `,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <h1 style={{ color: "black", padding: "10px" }}>
            {" "}
            TOSACON 2025 (HYDERABAD)
          </h1>
        </div>
      </div>
      <div className="globe-wrapper">
        <div
          className="globe"
          ref={globeRef}
          /*style={{  }}transform: `rotateY(${angle}deg) `*/
        >
          {images.map((src, i) => {
            const rotation = i * (360 / imageCount);
            return (
              <img
                key={i}
                src={src}
                className={`globe-image ${
                  i === currentIndex ? "in-focus" : ""
                }`}
                alt={`Slide ${i}`}
                style={{
                  transform: `
                    rotateY(${rotation}deg)
                    translateZ(300px)
                    translate(-50%, -50%)
                    `,
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="container">
        <p>
          Saanso made a notable appearance at the 10th Annual Conference of the
          Telangana Orthopaedic Surgeon's Association (TOSACON 2025), which took
          place from February 14th to 16th at HICC Novotel in Hyderabad. This
          esteemed gathering of orthopedic experts, surgeons, and healthcare
          professionals was an excellent venue for discussing the latest
          advancements in orthopedic care. Saanso’s participation underscored
          its dedication to providing the medical community with innovative,
          high-quality pharmaceutical solutions tailored to meet the specific
          needs of orthopedic and critical care.
        </p>
        <p>
          Throughout the conference, Saanso had the opportunity to display its
          expanding portfolio, engaging with prominent orthopedic specialists
          and industry leaders. The event served as an invaluable platform for
          fostering meaningful discussions, networking, and exploring potential
          collaborations aimed at improving patient care. This interaction
          highlighted Saanso’s commitment to excellence and its proactive role
          in advancing the field of orthopedics.
        </p>
        <p>
          As Saanso continues its journey in the healthcare sector, it remains
          committed to excellence and looks forward to making further
          contributions to the orthopedic community. The company is dedicated to
          enhancing patient care and is eager to maintain and build upon the
          connections established at TOSACON 2025.
        </p>
      </div>

      <Footer />
    </Fragment>
  );
};
export default Tosacon2025;
