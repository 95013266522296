import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage2 from "../HomePage2/HomePage2";

import AboutUsPage from "../AboutUsPage/AboutUsPage";

import PortfolioPage from "../PortfolioPage/PortfolioPage";
import PortfolioSinglePage from "../PortfolioSinglePage/PortfolioSinglePage";
import BlogPage from "../BlogPage/BlogPage";
import BlogDetails from "../BlogDetails/BlogDetails";
import ContactPage from "../ContactPage/ContactPage";

import Gallery from "../Gallery/Gallery";
import Careers from "../Careers/Careers";
import Category1 from "../Divisions/Category1";
import Category2 from "../Divisions/Category2";
import Category3 from "../Divisions/Category3";
import Category4 from "../Divisions/Category4";
import ResearchAndDevelopment from "../R&D/ResearchAndDevelopment";
import Manufacturing from "../Manufacturing/Manufacturing";
import FullScreenVideo from "../HomeVideo/HomeVideo";
import Job1 from "../Careers/ExecutiveMicrobiology";
import Job2 from "../Careers/TAssistantsExecutives";
import Job3 from "../Careers/HeadOfMarketing";
import Job4 from "../Careers/SalesExecutive";
import Blog1 from "../../components/blogs/blog1";
import Category5 from "../Divisions/Category5";
import SccLaunch from "../Gallery/SccLaunch";
import AnnualBudget2025 from "../Gallery/AnnualBudget2025";
import VizagEvent from "../Gallery/VizagEvent";
import BheemavaramEvent from "../Gallery/BheemavaramEvent";
import Tosacon2025 from "../Gallery/Tosacon2025";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FullScreenVideo />} />
          <Route path="/home" element={<HomePage2 />} />
          <Route path="cardio" element={<Category1 />} />
          <Route path="neuro" element={<Category2 />} />
          <Route path="respiro" element={<Category3 />} />
          <Route path="pharma" element={<Category4 />} />
          <Route path="critical-care" element={<Category5 />} />
          <Route path="/manufacturing" element={<Manufacturing />} />
          <Route
            path="/research-development"
            element={<ResearchAndDevelopment />}
          />
          <Route path="/executive-microbiology" element={<Job1 />} />
          <Route path="/technical-assistants-execuitves" element={<Job2 />} />
          <Route path="/head-of-marketing" element={<Job3 />} />
          <Route path="/sales-executive" element={<Job4 />} />
          <Route path="events" element={<Gallery />} />
          <Route path="ssc-launch" element={<SccLaunch />} />
          <Route path="budget-2025" element={<AnnualBudget2025 />} />
          <Route path="vizag-conference" element={<VizagEvent />} />
          <Route path="bhimavaram-conference" element={<BheemavaramEvent />} />
          <Route path="tosacon-2025" element={<Tosacon2025 />} />

          <Route path="about" element={<AboutUsPage />} />
          <Route path="careers" element={<Careers />} />
          <Route path="contact" element={<ContactPage />} />
          {/* <Route path="home" element={<HomePage2 />} /> */}
          {/* <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio_details/:slug"
            element={<PortfolioSinglePage />}
          /> */}
          <Route path="blogs" element={<BlogPage />} />
          <Route path="blog1" element={<Blog1 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
