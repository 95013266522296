import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/software-company-components/Footer/Footer";

import React, { Fragment, useState } from "react";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import csv1 from "../../images/actual/category-images/critical/1.png";
import csv2 from "../../images/actual/category-images/critical/critical.png";

import s1 from "../../images/actual/category-images/critical/s1/1.png";
import s3 from "../../images/actual/category-images/critical/s1/team.png";

import he1 from "../../images/actual/category-images/critical/departments/strip/1.png";
import he2 from "../../images/actual/category-images/critical/departments/strip/2.png";
import he3 from "../../images/actual/category-images/critical/departments/strip/3.png";
import he4 from "../../images/actual/category-images/critical/departments/strip/4.png";
import he5 from "../../images/actual/category-images/critical/departments/strip/5.png";

import b1 from "../../images/actual/category-images/critical/departments/box/1.png";
import b2 from "../../images/actual/category-images/critical/departments/box/2.png";
import b3 from "../../images/actual/category-images/critical/departments/box/3.png";
import b4 from "../../images/actual/category-images/critical/departments/box/4.png";
import b5 from "../../images/actual/category-images/critical/departments/box/5.png";
import b6 from "../../images/actual/category-images/critical/departments/box/6.png";

import bg from "../../images/backgrounds/common-banner.gif";

const Category5 = () => {
  const [activeFilter, setActiveFilter] = useState("department1");
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <Fragment>
      <div>
        <Header2 />
        <section
          className="software_company_hero_section xb-hidden"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="content_wrap">
                  <h1
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Critical Care (SCC)
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="about_section section_space">
        <div className="container">
          <div className="row align-items-center justify-content-lg-center">
            <div className="col-lg-6">
              <img src={csv2} />
            </div>
            <div className="col-lg-6">
              <h2 style={{ paddingLeft: "5%" }}>
                For Better Critical Care and Improved Quality of Life in
                Patients
              </h2>
            </div>
          </div>
        </div>
      </section>

      <div style={{ paddingTop: "75px", paddingBottom: "75px" }}>
        <div className="container">
          <div className="row">
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "25px",
              }}
            >
              Critical Care (SCC)
            </h1>
            <div className="col-lg-6">
              <p>
                Enhance your emergency and ICU readiness with our life-saving
                medications, meticulously formulated to provide swift and
                effective treatment for critically ill patients.
              </p>
              <br />
              <img src={csv1} alt="" />
            </div>
            <div
              className="col-lg-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img src={s1} alt="" />
              <br />
              <br />
              {/* <img src={s2} alt="" /> */}
            </div>
            <div
              className="col-lg-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img src={s3} alt="" />
              <br />
              <br />
              {/* <img src={s4} alt="" /> */}
            </div>
          </div>
        </div>
      </div>

      <section className="portfolio_section section_space bg-light">
        <div className="container">
          <div className="filter_elements_nav">
            <ul className="unordered_list justify-content-center">
              <li
                className={activeFilter === "department1" ? "active" : ""}
                onClick={() => handleFilterClick("department1")}
              >
                <img src={he1} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department2" ? "active" : ""}
                onClick={() => handleFilterClick("department2")}
              >
                <img src={he2} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department3" ? "active" : ""}
                onClick={() => handleFilterClick("department3")}
              >
                <img src={he3} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department4" ? "active" : ""}
                onClick={() => handleFilterClick("department4")}
              >
                <img src={he4} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department5" ? "active" : ""}
                onClick={() => handleFilterClick("department5")}
              >
                <img src={he5} alt="" style={{ width: "105px" }} />
              </li>
            </ul>
          </div>

          <div className="container">
            {activeFilter === "department1" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b1} alt="" style={{ height: "412px" }} />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Heparin Sodium 25000 IU
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department2" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b2} alt="" style={{ height: "412px" }} />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Meropenem 1000mg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department3" ? (
              <div className="row">
                <div className="row">
                  <div className="col-lg-12">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img src={b3} alt="" style={{ height: "412px" }} />{" "}
                    </div>
                    <h6 style={{ display: "flex", justifyContent: "center" }}>
                      Meropenem & Sulbactam 1.5gm
                    </h6>
                  </div>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department4" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b4} alt="" style={{ height: "412px" }} />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Piperacillin Tazobactam 2.25gm
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department5" ? (
              <div className="row">
                <div className="col-lg-6">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b5} alt="" style={{ height: "360px" }} />
                  </div>
                  <br />
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Propofol 100MG
                  </h6>
                </div>
                <div className="col-lg-6">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b6} alt="" style={{ height: "360px" }} />{" "}
                  </div>
                  <br />
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Propofol 200MG
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};
export default Category5;
