import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/software-company-components/Footer/Footer";
import banner from "../../images/actual/events/eventhome/banner.png";
import img1 from "../../images/actual/events/1.png";
import img2 from "../../images/actual/events/2.png";
import img3 from "../../images/actual/events/3.png";
import img4 from "../../images/actual/events/4.png";
import img5 from "../../images/actual/events/5.png";
import img6 from "../../images/actual/events/6.png";
import img7 from "../../images/actual/events/7.png";
import img8 from "../../images/actual/events/8.png";
import img9 from "../../images/actual/events/9.png";
import img10 from "../../images/actual/events/10.png";
import img11 from "../../images/actual/events/11.png";
import img12 from "../../images/actual/events/12.png";
import img13 from "../../images/actual/events/13.png";

import React, { Fragment, useState, useEffect, useRef } from "react";
import "./transition.css";
const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
];

const SccLaunch = ({ rotationSpeed = 60, step = 0.5 }) => {
  const globeRef = useRef(null);
  const [angle, setAngle] = useState(0);
  const imageCount = images.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setAngle((prev) => prev + step);
    }, rotationSpeed);
    return () => clearInterval(interval);
  }, [rotationSpeed, step]);

  const currentIndex =
    Math.round((angle % 360) / (360 / imageCount)) % imageCount;

  return (
    <Fragment>
      <Header2 />
      <div
        style={{
          backgroundImage: `url(${banner})  `,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <h1 style={{ color: "black", padding: "10px" }}>
            {" "}
            A Milestone Event: Launch of Our New SCC Division
          </h1>
        </div>
      </div>
      <div className="globe-wrapper">
        <div
          className="globe"
          ref={globeRef}
          /*style={{  }}transform: `rotateY(${angle}deg) `*/
        >
          {images.map((src, i) => {
            const rotation = i * (360 / imageCount);
            return (
              <img
                key={i}
                src={src}
                className={`globe-image ${
                  i === currentIndex ? "in-focus" : ""
                }`}
                alt={`Slide ${i}`}
                style={{
                  transform: `
                    rotateY(${rotation}deg)
                    translateZ(300px)
                    translate(-50%, -50%)
                    `,
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="container">
        <p>
          Saanso's launch of its new Critical Care Division (SCC) marks a
          significant expansion in its healthcare offerings, particularly in the
          realm of injectable medicines. This initiative is aligned with
          Saanso's mission to enhance patient care in critical environments such
          as intensive care units and emergency departments. By focusing on the
          safety, efficacy, and accessibility of their products, Saanso aims to
          support healthcare professionals in delivering superior patient
          outcomes.
        </p>
        <p>
          The establishment of the Saanso Critical Care Division underscores the
          company's commitment to quality and reliability in its products. The
          division's portfolio comprises a range of essential injectables,
          crucial for various high-stakes medical scenarios. These products are
          manufactured adhering to stringent quality standards, which is pivotal
          in ensuring their effectiveness and reliability in critical care
          settings.
        </p>
        <p>
          By aiming to establish a strong nationwide presence, Saanso is
          positioning its Critical Care Division as a key player in the
          healthcare sector. This strategic expansion not only broadens the
          company's reach but also enhances its capability to cater to the
          dynamic needs of healthcare institutions across the country. Saanso's
          focus on critical care injectables is set to make a significant impact
          in the field, contributing to better healthcare outcomes.
        </p>
      </div>

      <Footer />
    </Fragment>
  );
};
export default SccLaunch;
