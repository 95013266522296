import img1 from "../../images/actual/events/bhimavaram-conference/1.png";
import img2 from "../../images/actual/events/bhimavaram-conference/2.png";
import img3 from "../../images/actual/events/bhimavaram-conference/3.png";
import img4 from "../../images/actual/events/bhimavaram-conference/4.png";
import img5 from "../../images/actual/events/bhimavaram-conference/5.png";
import img6 from "../../images/actual/events/bhimavaram-conference/6.png";
import img7 from "../../images/actual/events/bhimavaram-conference/7.png";
import img8 from "../../images/actual/events/bhimavaram-conference/8.png";

import Carousel from "react-bootstrap/Carousel";
import { Image } from "react-bootstrap";

import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/software-company-components/Footer/Footer";
import banner from "../../images/actual/events/eventhome/banner.png";
import React, { Fragment, useState, useEffect, useRef } from "react";
import "./transition.css";
const images = [img1, img2, img3, img4, img5, img6, img7, img8];

const BheemavaramEvent = ({ rotationSpeed = 60, step = 0.5 }) => {
  const globeRef = useRef(null);
  const [angle, setAngle] = useState(0);
  const imageCount = images.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setAngle((prev) => prev + step);
    }, rotationSpeed);
    return () => clearInterval(interval);
  }, [rotationSpeed, step]);

  const currentIndex =
    Math.round((angle % 360) / (360 / imageCount)) % imageCount;

  return (
    <Fragment>
      <Header2 />
      <div
        style={{
          backgroundImage: `url(${banner})  `,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <h1 style={{ color: "black", padding: "10px" }}>
            {" "}
            AOI APCON September 13th to 15th 2024(Bhimavaram)
          </h1>
        </div>
      </div>
      <div className="globe-wrapper">
        <div
          className="globe"
          ref={globeRef}
          /*style={{  }}transform: `rotateY(${angle}deg) `*/
        >
          {images.map((src, i) => {
            const rotation = i * (360 / imageCount);
            return (
              <img
                key={i}
                src={src}
                className={`globe-image ${
                  i === currentIndex ? "in-focus" : ""
                }`}
                alt={`Slide ${i}`}
                style={{
                  transform: `
                    rotateY(${rotation}deg)
                    translateZ(300px)
                    translate(-50%, -50%)
                    `,
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="container">
        <p>
          Saanso actively engaged in AOI APCON 2024, hosted by the Association
          of Otolaryngologists of India in Bhimavaram from September 13th to
          15th. This event offered an essential platform for Saanso to
          assertively showcase its brand to a large audience of physicians,
          highlighting the diversity and quality of its product portfolio. The
          company’s presence underscored its commitment to delivering
          high-quality healthcare solutions and facilitated the strengthening of
          its market presence.
        </p>
        <p>
          Throughout AOI APCON 2024, Saanso took the opportunity to connect with
          leading healthcare professionals, enabling it to further reinforce its
          dedication to advancing medical care across various therapeutic areas.
          These interactions proved invaluable for building awareness and
          fostering relationships that support Saanso’s strategic goals in the
          healthcare industry.
        </p>
        <p>
          By participating in such a significant event, Saanso demonstrated its
          role as a key player in the medical community, committed to improving
          patient care and expanding its influence in the healthcare market. The
          company remains dedicated to its mission of enhancing medical
          treatment options and continuing its engagement with healthcare
          professionals at future events.
        </p>
      </div>
      <Footer />
    </Fragment>
  );
};
export default BheemavaramEvent;
