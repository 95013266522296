import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/software-company-components/Footer/Footer";
import banner from "../../images/actual/events/eventhome/banner.png";
import img1 from "../../images/actual/events/budget2025/1.png";
import img2 from "../../images/actual/events/budget2025/2.png";
import img3 from "../../images/actual/events/budget2025/3.png";
import img4 from "../../images/actual/events/budget2025/4.png";
import img5 from "../../images/actual/events/budget2025/5.png";
import img6 from "../../images/actual/events/budget2025/6.png";

import React, { Fragment, useState, useEffect, useRef } from "react";
import "./transition.css";
const images = [img1, img2, img3, img4, img5, img6];

const AnnualBudget2025 = ({ rotationSpeed = 60, step = 0.5 }) => {
  const globeRef = useRef(null);
  const [angle, setAngle] = useState(0);
  const imageCount = images.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setAngle((prev) => prev + step);
    }, rotationSpeed);
    return () => clearInterval(interval);
  }, [rotationSpeed, step]);

  const currentIndex =
    Math.round((angle % 360) / (360 / imageCount)) % imageCount;

  return (
    <Fragment>
      <Header2 />
      <div
        style={{
          backgroundImage: `url(${banner})  `,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <h1 style={{ color: "black", padding: "10px" }}>
            {" "}
            Annual Budget Meeting 2025-2026
          </h1>
        </div>
      </div>
      <div className="globe-wrapper">
        <div
          className="globe"
          ref={globeRef}
          /*style={{  }}transform: `rotateY(${angle}deg) `*/
        >
          {images.map((src, i) => {
            const rotation = i * (360 / imageCount);
            return (
              <img
                key={i}
                src={src}
                className={`globe-image ${
                  i === currentIndex ? "in-focus" : ""
                }`}
                alt={`Slide ${i}`}
                style={{
                  transform: `
                    rotateY(${rotation}deg)
                    translateZ(300px)
                    translate(-50%, -50%)
                    `,
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="container">
        <p>
          Saanso Annual Budget Meet, held from March 17th to 22nd at Mirror
          Resorts, Araku, was attended by over 200 people and marked a
          significant occasion for the company. This gathering provided a
          critical opportunity for reflection on the past year's
          accomplishments, a thorough analysis of areas needing improvement, and
          strategic planning for future endeavors. Discussions focused on key
          performance milestones, market expansion, and operational
          efficiencies, shedding light on both the triumphs and challenges faced
          throughout the year.
        </p>
        <p>
          During the meeting, the team demonstrated a strong commitment to
          excellence, crafting an ambitious roadmap for the fiscal year 2025-26
          with a clear objective to achieve 100% performance. The atmosphere was
          charged with renewed energy and motivation, which was essential for
          detailed strategic planning. This collective effort is aimed at
          bolstering Saanso's market presence and propelling innovation forward.
        </p>
        <p>
          The event wrapped up on a high note, leaving participants with a
          unified vision to push the company’s success to greater heights in the
          upcoming year. The resolve to enhance Saanso’s market impact and drive
          innovation was palpable, setting a positive and determined tone for
          the future.
        </p>
      </div>

      <Footer />
    </Fragment>
  );
};
export default AnnualBudget2025;
